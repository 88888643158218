<template>
  <Form
    :initial-values="formdata"
    @submit="submit"
    @invalid-submit="onInvalidSubmit"
    v-slot="{ values, resetForm }"
    ref="bulkForm"
  >
    <div class="form-group">
      <label for="lookup_contacts">{{ $tc('phoneOrEmail', 2) }}</label>
      <Field
        as="textarea"
        name="lookup_contacts"
        id="lookup_contacts"
        rules="required|phone_email_list"
        :validate-On-Mount="needPrevalidation"
        :validateOnInput="false"
        class="form-control mb-0 form-group"
        v-model="formdata.lookup_contacts"
        placeholder="+13231112233, 1(787)1112233"
        @blur="recipientsBlur"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <small
        class="form-text text-muted"
      >{{ $t('recipientsContact') }}</small>
      <small
        class="form-text text-muted"
      >{{ $t('recipientsContactQuantity') }}: {{ recipientsCount }} / {{ lookupCountLimit }}</small>
      <small
        v-if="duration.quantity"
        class="form-text text-muted"
      >{{ $t('estimatedDuration') }}:
        <template v-if="duration.quantity === 1 && duration.unit === 'min'">{{ $t('estimatedDurationMin') }}</template>
        <template v-else>~{{ duration.quantity }} {{ $tc('units.' + duration.unit, duration.quantity) }}</template>
      </small>
      <ErrorMessage
        as="div"
        name="lookup_contacts"
        class="error-message"
        v-slot="{message}"
      >{{message ? message.replace("lookup_contacts", $t('lookup_contacts')) + ' ' + inValidRecipients : ''}}</ErrorMessage>
    </div>
    <div class="mb-2">
      <label for="status_callback" class="cr" v-html="$t('callbackUrl')"></label>
      <small class="form-text text-muted">{{ $t('lookupCallbackHelp') }}</small>
      <Field
        as="input"
        type="text"
        name="status_callback"
        id="status_callback"
        class="form-control mb-0 form-group"
        rules=""
        v-model="formdata.status_callback"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <small class="form-text text-muted">{{ $t('optional') }}</small>
    </div>
    <div class="mb-2">
      <label for="status_callback_header" class="cr" v-html="$t('callbackHeader')"></label>
      <small class="form-text text-muted">{{ $t('lookupCallbackHeaderHelp') }}</small>
      <Field
        as="input"
        type="text"
        name="status_callback_header"
        id="status_callback_header"
        class="form-control mb-0 form-group"
        rules=""
        v-model="formdata.status_callback_header"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <small class="form-text text-muted">{{ $t('optional') }}</small>
    </div>
    <div class="checkbox checkbox-primary d-inline">
      <hint title="lookupContactDetailsHelpText" placement="left">
        <Field
          as="input"
          type="checkbox"
          name="contact_details"
          id="contact_details"
          rules=""
          v-model="formdata.contact_details"
          :value="true"
          :unchecked-value="false"
          :validate-On-Mount="true"
          @change="() => { fieldChanged(values) }"
        ></Field>
        <label for="contact_details" class="cr" v-html="$t('lookupContactDetails')"></label>
        <small class="form-text text-muted">{{ $t('lookupContactDetailsHelpText') }}</small>
      </hint>
      <ErrorMessage
        as="div"
        name="contact_details"
        class="error-message"
      />
    </div>
    <div class="checkbox checkbox-primary d-inline">
      <Field
        as="input"
        type="checkbox"
        name="lookup_agreement"
        id="lookup_agreement"
        rules="required"
        v-model="formdata.lookup_agreement"
        :value="true"
        :unchecked-value="false"
        :validate-On-Mount="true"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <label for="lookup_agreement" class="cr" v-html="$t('termsRulesAgreement')"></label>
      <ErrorMessage
        as="div"
        name="lookup_agreement"
        class="error-message"
      />
    </div>
    <div :class="['row', submitButton.wrapper]">
      <div class="col col-6">
        <button
          :disabled="isDisabled === true"
          :class="submitButton.class"
        ><div class="spinner-border spinner-border-sm mr-1" v-if="isDisabled === true"></div>{{ submitButton.text }}</button>
        <small class="form-text text-muted" v-if="license && license.bulk_send_status === 'PREMODERATION'">
          {{ $t('bulkMessagesPremoderationNotification') }}.
        </small>
      </div>
      <div class="col col-6">
          <div :class="resetButton.wrapper">
            <modal-wrapper>
              <template v-slot="{ close }">
                <confirmation-modal
                  :close="close"
                  :content="$t('confirmationResetForm')"
                  @confirm="() => {clearForm(resetForm)}"
                ></confirmation-modal>
              </template>
              <template v-slot:trigger="{ open }">
                <button
                  @click.prevent="open"
                  :class="resetButton.class"
                  :disabled="resetDisabled"
                >{{ resetButton.text }}</button>
              </template>
            </modal-wrapper>
          </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useLookupCreate } from '@/application/composables/lookupCreate.js'
import { useExtractRecipients } from '@/application/composables/extractRecipients.js'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { store } from '@/application/store'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { getDuration } from '@/application/services/message'
import { lookupCountLimit } from '@/application/utils/limits.js'

export default {
  name: 'LookupForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { extractValidRecipients, extractInValidRecipients } = useExtractRecipients(lookupCountLimit)
    const {
      license,
      submit,
      senderNames,
      formdata,
      submitButton,
      resetButton,
      isDisabled,
      cleanFieldValue,
      onChangeHandler,
      onChangeDateHandler,
      needPrevalidation,
    } = useLookupCreate(props.license, lookupCountLimit)
    const { openModal } = useModal()
    const idOfEstimationRequest = ref(null)
    const setEstimationTime = (count) => {
      console.log('SetEstimationTime', count)
      const currentId = Date.now().toString(36) + Math.random().toString(36).substr(2)
      idOfEstimationRequest.value = currentId
      if (count > 0) {
        getDuration(count)
          .then(({ data }) => {
            if (currentId === idOfEstimationRequest.value) {
              duration.value.quantity = data.value
              duration.value.unit = data.measurement
            }
          })
      } else {
        duration.value.quantity = 0
        duration.value.unit = null
      }
    }

    const formdataRef = ref(formdata)
    const duration = ref({})
    const contactsCount = computed(() => extractValidRecipients(formdataRef.value.lookup_contacts).length)
    watch(contactsCount, setEstimationTime)
    watch(contactsCount, console.log('contactsCount: ', contactsCount.value))
    const inValidRecipients = ref(extractInValidRecipients(formdataRef.value.lookup_contacts).join(', '))
    const resetDisabled = computed(() => {
      const data = store.getters.getLookupFormCache(license)
      return !data || Object.values(data).filter(item => !!item).length === 0
    })
    const fieldChanged = (values) => {
      onChangeHandler(values)
    }
    const dateChanged = (date) => { onChangeDateHandler(date) }
    const i18n = useI18n()

    onMounted(() => {
      if (contactsCount.value > 0) {
        setEstimationTime(contactsCount.value)
      }
    })

    return {
      submit,
      submitButton,
      resetButton,
      senderNames,
      formdata: formdataRef,
      isDisabled,
      recipientsCount: contactsCount,
      inValidRecipients,
      fieldChanged,
      cleanFieldValue,
      needPrevalidation,
      dateChanged,
      resetDisabled,
      duration,
      onInvalidSubmit({ errors }) {
        openModal(i18n.t('error'), [...new Set(Object.values(errors))].join('<br>'))
      },
      recipientsBlur() {
        inValidRecipients.value = extractInValidRecipients(formdataRef.value.lookup_contacts).join(', ')
      },
      lookupCountLimit,
    }
  },
  methods: {
    clearForm(resetForm) {
      this.resetButton.clearStorage()
      resetForm()
      this.$refs.bulkForm.setValues(this.cleanFieldValue)
      for (const key in this.formdata) {
        if (this.cleanFieldValue[key] !== undefined) {
          this.formdata[key] = this.cleanFieldValue[key]
        }
      }
    },
  },
}
</script>
